/**
 *
 * @fileoverview environment
 *
 * @version 1.0
 *
 */

export const environment = {
  version: '1.5.1',
  production: false,
  server: 'https://dev.finberry.technit.solutions/api/v1/',
  mapApi: 'AIzaSyCIIDhiBcd1Q7s93GhYTpUql9kX1XqHuVM',
  secureKey: Math.random().toString(32).substring(2, 32),
  oneSignalAppId: '144f86cb-747a-4af3-971d-94cadf9abf2e',
  encryptToken: 'cWV6OWxjbWt4aXVnMG5ubXQ2Z2ZnZmQ3eGdubWY5dDg=',
  status: {
    pending: 'Pendiente',
    paid: 'Pagado',
    pre_authorized: 'Pre Autorizado',
    voided: 'Anulado',
    refunded: 'Reembolsado',
    expired: 'Expirado',
    cancelled: 'Cancelado',
    partially: 'Parcialmente',
  },
};
