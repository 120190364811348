/**
 *
 * @fileoverview GuardService
 *
 * @version 1.0
 *
 */

import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class GuardService {
  constructor(private auth: AuthService) {}

  canActivate(): boolean {
    return this.auth.isAuthenticated();
  }
}
