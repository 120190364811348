/**
 *
 * @fileoverview AppRoutingModule
 *
 * @version 1.0
 *
 */

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GuardService } from './services/auth/guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },

  /* MENU AUTH */
  {
    path: 'login',
    loadChildren: () => import('./pages/auth/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'buiness',
    loadChildren: () => import('./pages/auth/buiness/buiness.module').then((m) => m.BuinessPageModule),
  },
  {
    path: 'phone',
    loadChildren: () => import('./pages/auth/phone/phone.module').then((m) => m.PhonePageModule),
  },
  {
    path: 'code',
    loadChildren: () => import('./pages/auth/code/code.module').then((m) => m.CodePageModule),
  },
  {
    path: 'signup',
    loadChildren: () => import('./pages/auth/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: 'key',
    loadChildren: () => import('./pages/auth/key/key.module').then((m) => m.KeyPageModule),
  },
  {
    path: 'change',
    loadChildren: () => import('./pages/auth/change/change.module').then((m) => m.ChangePageModule),
  },
  {
    path: 'welcome',
    loadChildren: () => import('./pages/welcome/welcome.module').then((m) => m.WelcomePageModule),
  },

  /* MENU HOME */
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [GuardService],
  },

  /* MENU NOTIFICATIONS */
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then((m) => m.NotificationsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'notifications/show',
    loadChildren: () =>
      import('./pages/notifications-show/notifications-show.module').then((m) => m.NotificationsShowPageModule),
    canActivate: [GuardService],
  },

  /* MENU PROFILE */
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/account',
    loadChildren: () =>
      import('./pages/profile-account/profile-account.module').then((m) => m.ProfileAccountPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/mission',
    loadChildren: () =>
      import('./pages/profile-mission/profile-mission.module').then((m) => m.ProfileMissionPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/refers',
    loadChildren: () => import('./pages/profile-refers/profile-refers.module').then((m) => m.ProfileRefersPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/coupons',
    loadChildren: () =>
      import('./pages/profile-coupons/profile-coupons.module').then((m) => m.ProfileCouponsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/coupons/show',
    loadChildren: () =>
      import('./pages/profile-coupons-show/profile-coupons-show.module').then((m) => m.ProfileCouponsShowPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/bills',
    loadChildren: () => import('./pages/profile-bills/profile-bills.module').then((m) => m.ProfileBillsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/bills/data',
    loadChildren: () =>
      import('./pages/profile-bills-data/profile-bills-data.module').then((m) => m.ProfileBillsDataPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/bills/add',
    loadChildren: () =>
      import('./pages/profile-bills-add/profile-bills-add.module').then((m) => m.ProfileBillsAddPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/bills/show',
    loadChildren: () =>
      import('./pages/profile-bills-show/profile-bills-show.module').then((m) => m.ProfileBillsShowPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/bills/invoice',
    loadChildren: () =>
      import('./pages/profile-bills-invoice/profile-bills-invoice.module').then((m) => m.ProfileBillsInvoicePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'profile/session',
    loadChildren: () =>
      import('./pages/profile-session/profile-session.module').then((m) => m.ProfileSessionPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'beneficiaries',
    loadChildren: () => import('./pages/beneficiary/beneficiary.module').then((m) => m.BeneficiaryPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'beneficiaries-add',
    loadChildren: () =>
      import('./pages/beneficiary-add/beneficiary-add.module').then((m) => m.BeneficiaryAddPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'beneficiary-show',
    loadChildren: () =>
      import('./pages/beneficiary-show/beneficiary-show.module').then((m) => m.BeneficiaryShowPageModule),
  },
  {
    path: 'beneficiary-response',
    loadChildren: () =>
      import('./pages/beneficiary-response/beneficiary-response.module').then((m) => m.BeneficiaryResponsePageModule),
    canActivate: [GuardService],
  },

  /* MENU SCAN PAY */
  {
    path: 'scan-pay',
    loadChildren: () => import('./pages/scan-pay/scan-pay.module').then((m) => m.ScanPayPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'scan-pay/product',
    loadChildren: () =>
      import('./pages/scan-pay-product/scan-pay-product.module').then((m) => m.ScanPayProductPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'scan-pay/check',
    loadChildren: () => import('./pages/scan-pay-check/scan-pay-check.module').then((m) => m.ScanPayCheckPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'scan-pay/resume',
    loadChildren: () => import('./pages/scan-pay-resume/scan-pay-resume.module').then((m) => m.ScanPayResumePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'scan-pay/response',
    loadChildren: () =>
      import('./pages/scan-pay-response/scan-pay-response.module').then((m) => m.ScanPayResponsePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'scan-pay/show',
    loadChildren: () => import('./pages/scan-pay-show/scan-pay-show.module').then((m) => m.ScanPayShowPageModule),
    canActivate: [GuardService],
  },

  /* MENU SEND */
  {
    path: 'send',
    loadChildren: () => import('./pages/send/send.module').then((m) => m.SendPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'send-resume',
    loadChildren: () => import('./pages/send-resume/send-resume.module').then((m) => m.SendResumePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'send-response',
    loadChildren: () => import('./pages/send-response/send-response.module').then((m) => m.SendResponsePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'send-show',
    loadChildren: () => import('./pages/send-show/send-show.module').then((m) => m.SendShowPageModule),
    canActivate: [GuardService],
  },

  /* MENU CARDS */
  {
    path: 'cards',
    loadChildren: () => import('./pages/cards/cards.module').then((m) => m.CardsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'cards-add',
    loadChildren: () => import('./pages/cards-add/cards-add.module').then((m) => m.CardsAddPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'cards-show',
    loadChildren: () => import('./pages/cards-show/cards-show.module').then((m) => m.CardsShowPageModule),
  },
  {
    path: 'cards-success',
    loadChildren: () => import('./pages/cards-success/cards-success.module').then((m) => m.CardsSuccessPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'cards-up',
    loadChildren: () => import('./pages/cards-up/cards-up.module').then((m) => m.CardsUpPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'cards-active',
    loadChildren: () => import('./pages/cards-active/cards-active.module').then((m) => m.CardsActivePageModule),
    canActivate: [GuardService],
  },

  /* MENU RECURRING */
  {
    path: 'recurring',
    loadChildren: () => import('./pages/recurring/recurring.module').then((m) => m.RecurringPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'recurring/add',
    loadChildren: () => import('./pages/recurring-add/recurring-add.module').then((m) => m.RecurringAddPageModule),
    canActivate: [GuardService],
  },

  /* MENU PROMOTIONS */
  {
    path: 'promotions',
    loadChildren: () => import('./pages/promotions/promotions.module').then((m) => m.PromotionsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'promotions-show',
    loadChildren: () =>
      import('./pages/promotions-show/promotions-show.module').then((m) => m.PromotionsShowPageModule),
    canActivate: [GuardService],
  },

  /* MENU CALCULATE */
  {
    path: 'calculate',
    loadChildren: () => import('./pages/calculate/calculate.module').then((m) => m.CalculatePageModule),
    canActivate: [GuardService],
  },

  /* MENU PREFERENCES */
  {
    path: 'preferences',
    loadChildren: () => import('./pages/preferences/preferences.module').then((m) => m.PreferencesPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'preferences/notifications',
    loadChildren: () =>
      import('./pages/preferences-notifications/preferences-notifications.module').then(
        (m) => m.PreferencesNotificationsPageModule
      ),
    canActivate: [GuardService],
  },
  {
    path: 'preferences/security',
    loadChildren: () =>
      import('./pages/preferences-security/preferences-security.module').then((m) => m.PreferencesSecurityPageModule),
    canActivate: [GuardService],
  },
  /* MENU TUTORIAL */
  {
    path: 'tutorial/center',
    loadChildren: () =>
      import('./pages/tutorial-center/tutorial-center.module').then((m) => m.TutorialCenterPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'tutorial/category',
    loadChildren: () =>
      import('./pages/tutorial-category/tutorial-category.module').then((m) => m.TutorialCategoryPageModule),
    canActivate: [GuardService],
  },
  /* MENU HELP */
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then((m) => m.HelpPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'help/center',
    loadChildren: () => import('./pages/help-center/help-center.module').then((m) => m.HelpCenterPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'help/category',
    loadChildren: () => import('./pages/help-category/help-category.module').then((m) => m.HelpCategoryPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'help/detail',
    loadChildren: () => import('./pages/help-detail/help-detail.module').then((m) => m.HelpDetailPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'help/support',
    loadChildren: () => import('./pages/help-support/help-support.module').then((m) => m.HelpSupportPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'help/ticket',
    loadChildren: () => import('./pages/help-ticket/help-ticket.module').then((m) => m.HelpTicketPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'help/ticket/new',
    loadChildren: () => import('./pages/help-ticket-new/help-ticket-new.module').then((m) => m.HelpTicketNewPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'help/ticket/show',
    loadChildren: () =>
      import('./pages/help-ticket-show/help-ticket-show.module').then((m) => m.HelpTicketShowPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'transactions',
    loadChildren: () => import('./pages/transactions/transactions.module').then((m) => m.TransactionsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'transactions/show',
    loadChildren: () =>
      import('./pages/transaction-show/transaction-show.module').then((m) => m.TransactionShowPageModule),
    canActivate: [GuardService],
  },

  /* MENU BUSSINESS */
  {
    path: 'business/generate',
    loadChildren: () => import('./pages/business/generate/generate.module').then((m) => m.GeneratePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'business/generate/info',
    loadChildren: () =>
      import('./pages/business/generate-info/generate-info.module').then((m) => m.GenerateInfoPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'business/generate/load',
    loadChildren: () =>
      import('./pages/business/generate-load/generate-load.module').then((m) => m.GenerateLoadPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'business/generate/success',
    loadChildren: () =>
      import('./pages/business/generate-success/generate-success.module').then((m) => m.GenerateSuccessPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'business/operations',
    loadChildren: () => import('./pages/business/operations/operations.module').then((m) => m.OperationsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'business/operations/show',
    loadChildren: () =>
      import('./pages/business/operations-show/operations-show.module').then((m) => m.OperationsShowPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'business/profile',
    loadChildren: () => import('./pages/business/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [GuardService],
  },
  {
    path: 'business/promotions',
    loadChildren: () => import('./pages/business/promotions/promotions.module').then((m) => m.PromotionsPageModule),
    canActivate: [GuardService],
  },
  {
    path: 'business/promotions-show',
    loadChildren: () =>
      import('./pages/business/promotions-show/promotions-show.module').then((m) => m.PromotionsShowPageModule),
    canActivate: [GuardService],
  },
  /* MODALS */
  {
    path: 'modal-alert',
    loadChildren: () => import('./modals/modal-alert/modal-alert.module').then((m) => m.ModalAlertPageModule),
  },
  {
    path: 'modal-cards',
    loadChildren: () => import('./modals/modal-cards/modal-cards.module').then((m) => m.ModalCardsPageModule),
  },
  {
    path: 'modal-credit',
    loadChildren: () => import('./modals/modal-credit/modal-credit.module').then((m) => m.ModalCreditPageModule),
  },
  {
    path: 'modal-help',
    loadChildren: () => import('./modals/modal-help/modal-help.module').then((m) => m.ModalHelpPageModule),
  },
  {
    path: 'ads',
    loadChildren: () => import('./pages/ads/ads.module').then((m) => m.AdsPageModule),
  },
  {
    path: 'ads-show',
    loadChildren: () => import('./pages/ads-show/ads-show.module').then((m) => m.AdsShowPageModule),
  },
  {
    path: 'cards-up-address',
    loadChildren: () =>
      import('./pages/cards-up-address/cards-up-address.module').then((m) => m.CardsUpAddressPageModule),
  },
  {
    path: 'cards-up-id',
    loadChildren: () => import('./pages/cards-up-id/cards-up-id.module').then((m) => m.CardsUpIdPageModule),
  },
  {
    path: 'cards-up-sumary',
    loadChildren: () => import('./pages/cards-up-sumary/cards-up-sumary.module').then((m) => m.CardsUpSumaryPageModule),
  },
  {
    path: 'modal-image',
    loadChildren: () => import('./modals/modal-image/modal-image.module').then((m) => m.ModalImagePageModule),
  },
  {
    path: 'modal-info',
    loadChildren: () => import('./modals/modal-info/modal-info.module').then((m) => m.ModalInfoPageModule),
  },
  {
    path: 'modal-photo',
    loadChildren: () => import('./modals/modal-photo/modal-photo.module').then((m) => m.ModalPhotoPageModule),
  },
  {
    path: 'modal-transaction',
    loadChildren: () =>
      import('./modals/modal-transaction/modal-transaction.module').then((m) => m.ModalTransactionPageModule),
  },
  {
    path: 'modal-bank',
    loadChildren: () => import('./modals/modal-bank/modal-bank.module').then((m) => m.ModalBankPageModule),
  },
  {
    path: 'modal-beneficiaries',
    loadChildren: () =>
      import('./modals/modal-beneficiaries/modal-beneficiaries.module').then((m) => m.ModalBeneficiariesPageModule),
  },
  {
    path: 'modal-commissions',
    loadChildren: () =>
      import('./modals/modal-commissions/modal-commissions.module').then((m) => m.ModalCommissionsPageModule),
  },
  {
    path: 'transactions-tickets',
    loadChildren: () =>
      import('./pages/transactions-tickets/transactions-tickets.module').then((m) => m.TransactionsTicketsPageModule),
  },
  {
    path: 'modal-billings',
    loadChildren: () => import('./modals/modal-billings/modal-billings.module').then((m) => m.ModalBillingsPageModule),
  },
  {
    path: 'modal-transaction-show',
    loadChildren: () =>
      import('./modals/modal-transaction-show/modal-transaction-show.module').then(
        (m) => m.ModalTransactionShowPageModule
      ),
  },
  {
    path: 'modal-verify',
    loadChildren: () => import('./modals/modal-verify/modal-verify.module').then((m) => m.ModalVerifyPageModule),
  },
  {
    path: 'verify',
    loadChildren: () => import('./pages/verify/verify.module').then((m) => m.VerifyPageModule),
  },
  {
    path: 'modal-pin',
    loadChildren: () => import('./modals/modal-pin/modal-pin.module').then((m) => m.ModalPinPageModule),
  },
  {
    path: 'modal-pin-change',
    loadChildren: () =>
      import('./modals/modal-pin-change/modal-pin-change.module').then((m) => m.ModalPinChangePageModule),
  },
  {
    path: 'modal-support',
    loadChildren: () => import('./modals/modal-support/modal-support.module').then((m) => m.ModalSupportPageModule),
  },
  {
    path: 'modal-concept',
    loadChildren: () => import('./modals/modal-concept/modal-concept.module').then((m) => m.ModalConceptPageModule),
  },
  {
    path: 'news-show',
    loadChildren: () => import('./pages/news-show/news-show.module').then((m) => m.NewsShowPageModule),
  },
  {
    path: 'modal-frequency',
    loadChildren: () =>
      import('./modals/modal-frequency/modal-frequency.module').then((m) => m.ModalFrequencyPageModule),
  },
  {
    path: 'modal-welcome',
    loadChildren: () => import('./modals/modal-welcome/modal-welcome.module').then((m) => m.ModalWelcomePageModule),
  },
  {
    path: 'modal-tutorial',
    loadChildren: () => import('./modals/modal-tutorial/modal-tutorial.module').then((m) => m.ModalTutorialPageModule),
  },
  {
    path: 'modal-referrals',
    loadChildren: () =>
      import('./modals/modal-referrals/modal-referrals.module').then((m) => m.ModalReferralsPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
